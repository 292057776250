
import Vue from "vue";

import http from "@/http";

import { date, excerpt } from "@/filters";

import {
  ProcedureResponse,
  Procedure,
  Breadcrumb,
  Item,
  Store,
} from "@/interfaces";

import { mdiMagnify } from "@mdi/js";

export interface Query {
  store_id: number;
  status: string;
  type: string;
  page: number;
}

export interface DataType {
  procedures: Procedure[];
  lastPage: number;
  query: Query;
}

export default Vue.extend({
  filters: {
    date,
    excerpt,
  },
  data(): DataType {
    return {
      procedures: [],
      lastPage: 0,
      query: {
        page: 1,
        store_id: 0,
        status: "",
        type: "",
      },
    };
  },
  computed: {
    icon() {
      return {
        mdiMagnify,
      };
    },
    breadcrumbs(): Breadcrumb[] {
      const { target } = this.$route.params;

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: `各種手続き`,
          disabled: true,
        },
      ];
    },
    headers() {
      return [
        {
          text: "受付日",
          value: "created_at",
        },
        {
          text: "手続き",
          value: "type",
        },
        {
          text: "ステータス",
          value: "status",
        },
        {
          text: "店舗",
          value: "store",
        },
        {
          text: "部屋",
          value: "room",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
    states(): Item[] {
      return [
        { value: "1", text: "新規" },
        { value: "2", text: "進行中" },
        { value: "3", text: "完了" },
        { value: "9", text: "キャンセル" },
        { value: "", text: "--" },
      ];
    },

    types(): Item[] {
      return [
        { value: "informationChange", text: "姓変更" },
        { value: "personChange", text: "入居者増減" },
        { value: "workplaceChange", text: "勤務先変更" },
        { value: "guarantorChange", text: "緊急連絡先情報変更" },
        { value: "renewal", text: "契約更新" },
        { value: "carChange", text: "車両変更" },
        { value: "", text: "--" },
      ];
    },

    stores(): Item[] {
      return this.$store.state.stores
        .map((store: Store) => {
          return {
            text: store.name,
            value: store.id,
          };
        })
        .concat([
          {
            text: "--",
            value: 0,
          },
        ]);
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    handleQuery() {
      let page = 1;
      let store_id = 0;
      let status = "";
      let type = "";

      if (typeof this.$route.query.page === "string") {
        page = +this.$route.query.page;
      }

      if (typeof this.$route.query.store_id === "string") {
        store_id = +this.$route.query.store_id;
      }

      if (typeof this.$route.query.status === "string") {
        status = this.$route.query.status;
      }

      if (typeof this.$route.query.type === "string") {
        type = this.$route.query.type;
      }

      this.query = {
        page,
        store_id,
        status,
        type,
      };
    },
    async fetchData() {
      this.handleQuery();

      const { page, status, store_id, type } = this.query;

      const params = {
        page,
        store_id,
        status,
        type,
      };

      const { data } = await http.get<ProcedureResponse>("procedures", {
        params,
      });

      this.procedures = data.data;
      this.lastPage = data.last_page;
    },
    click(item: Procedure) {
      const to = `/procedure/${item.id}`;

      this.$router.push(to);
    },
    async submit() {
      await this.$router.replace({
        path: "/procedures",
        query: {
          page: this.query.page.toString(),
          store_id: this.query.store_id.toString(),
          status: this.query.status,
          type: this.query.type,
        },
      });
    },
  },
});
